import React, { createContext, useState, useEffect, useCallback } from "react";

export const SelectionContext = createContext();
const MAX_SELECTIONS = 4;
const TIME_TO_ROTATE = 2500;

export default function SelectionContextProvider({ children }) {
    const [selection, setSelectionRaw] = useState(0);

    const resetTimer = useCallback(() => {
        clearInterval(window.selectionInterval);
        window.selectionInterval = setInterval(() => {
            setSelectionRaw((prevSelection) => (prevSelection + 1) % MAX_SELECTIONS);
        }, TIME_TO_ROTATE);
    }, []);

    const setSelection = useCallback(
        (value) => {
            setSelectionRaw(value);
            resetTimer();
        },
        [resetTimer]
    );

    useEffect(() => {
        resetTimer();
        return () => clearInterval(window.selectionInterval);
    }, [resetTimer]);

    return <SelectionContext.Provider value={{ selection, setSelection }}>{children}</SelectionContext.Provider>;
}
