export const smoothScrollTo = (elementId, duration) => {
    const target = document.getElementById(elementId);
    if (!target) return;

    const targetPosition = target.getBoundingClientRect().top;
    const startPosition = window.scrollY;
    const distance = targetPosition - 0;
    let startTime = null;

    const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easeInOutCubic(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const easeInOutCubic = (time, start, distance, duration) => {
        time /= duration / 2;
        if (time < 1) return (distance / 2) * time * time * time + start;
        time -= 2;
        return (distance / 2) * (time * time * time + 2) + start;
    };

    requestAnimationFrame(animation);
};