import React, { useContext } from "react";
import { SelectionContext } from "./SelectionContextProvider";

export default function SelectionButton({ children, index }) {
    const intIndex = Number(index);
    const { selection, setSelection } = useContext(SelectionContext);
    return (
        <button
            className={`flex items-center text-sm font-medium text-slate-50 rounded border bg-slate-800/25 w-full px-3 py-2 transition duration-150 ease-in-out hover:opacity-100 ${
                selection !== intIndex ? "border-slate-700 opacity-50" : "border-purple-700 shadow shadow-purple-500/25"
            }`}
            onClick={() => setSelection(intIndex)}
        >
            {React.Children.map(children, (child, index) => (
                <React.Fragment key={index}>
                    {index === 0 && child}
                    {index === 0 && <span className="mr-2" />}
                    {index === 1 && child}
                </React.Fragment>
            ))}
        </button>
    );
}
