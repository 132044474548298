import React, { useEffect } from "react";
// import "./css/additional-styles/theme.css";
// import "./css/additional-styles/utility-patterns.css";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import DefaultLayout from "./pages/DefaultLayout";
import Home from "./pages/Home";
import PricingSection from "./pages/PricingSection";
import { initGA, trackPageView, GA_MEASUREMENT_ID } from "./GoogleAnalytics";

const LandingPage = () => {
    const location = useLocation();

    useEffect(() => {
        trackPageView(location.pathname);
    }, [location]);

    return (
        <DefaultLayout>
            <Home />
        </DefaultLayout>
    );
};

const PricingPage = () => {
    return (
        <DefaultLayout>
            <PricingSection />
        </DefaultLayout>
    );
};

function App() {
    useEffect(() => {
        // Add Google Analytics Script
        const script = document.createElement("script");
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            initGA();
        };

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/home/pricing" element={<PricingPage />} />
            </Routes>
        </Router>
    );
}

export default App;
