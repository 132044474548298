import { useState } from "react";

const TOTAL_PACKAGES = 3;

export default function Pricing() {
    const [annual, setAnnual] = useState(true);

    return (
        <div className="relative">
            {/* Blurred shape */}
            <div
                className="max-md:hidden absolute bottom-0 -mb-20 left-2/3 -translate-x-1/2 blur-2xl opacity-70 pointer-events-none"
                aria-hidden="true"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                    <defs>
                        <linearGradient id="bs5-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                            <stop offset="0%" stopColor="#A855F7" />
                            <stop offset="100%" stopColor="#6366F1" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                    <path
                        fill="url(#bs5-a)"
                        fillRule="evenodd"
                        d="m661 736 461 369-284 58z"
                        transform="matrix(1 0 0 -1 -661 1163)"
                    />
                </svg>
            </div>
            {/* Content */}
            <div
                className={`
                grid md:grid-cols-4 xl:-mx-6 text-sm

                // Padding and margins
                [&>div:nth-of-type(-n+4)]:py-6 
                [&>div:nth-last-of-type(-n+4)]:pb-6
                max-md:[&>div:nth-last-of-type(-n+4)]:mb-8

                // Rounded corners
                max-md:[&>div:nth-of-type(2)]:rounded-t-3xl // 1
                max-md:[&>div:nth-of-type(3)]:rounded-t-3xl // 2
                max-md:[&>div:nth-of-type(4)]:rounded-t-3xl // 3
                max-md:[&>div:nth-last-of-type(3)]:rounded-b-3xl
                max-md:[&>div:nth-last-of-type(2)]:rounded-b-3xl
                max-md:[&>div:nth-last-of-type(1)]:rounded-b-3xl
                md:[&>div:nth-of-type(2)]:rounded-tr-3xl
                md:[&>div:nth-of-type(4)]:rounded-tl-3xl
                md:[&>div:nth-last-of-type(3)]:rounded-br-3xl
                md:[&>div:nth-last-of-type(1)]:rounded-bl-3xl

                // Background and layout
                [&>div]:bg-slate-700/20
                [&>div:nth-of-type(4n+1)]:bg-transparent
                
                // Mobile ordering and visibility
                max-md:[&>div:nth-of-type(4n+5)]:hidden
                max-md:[&>div:nth-of-type(4n+2)]:order-1
                max-md:[&>div:nth-of-type(4n+3)]:order-2
                max-md:[&>div:nth-of-type(4n+4)]:order-3

                // Featured plan styling (purple border)
                [&>div:nth-of-type(4n+3)]:relative
                before:[&>div:nth-of-type(4n+3)]:absolute
                before:[&>div:nth-of-type(4n+3)]:-inset-px
                before:[&>div:nth-of-type(4n+3)]:rounded-[inherit]
                before:[&>div:nth-of-type(4n+3)]:border-x-2
                before:[&>div:nth-of-type(3)]:border-t-2
                before:[&>div:nth-last-of-type(2)]:border-b-2
                before:[&>div:nth-of-type(4n+3)]:border-purple-500
                before:[&>div:nth-of-type(4n+3)]:-z-10
                before:[&>div:nth-of-type(4n+3)]:pointer-events-none
            `}
            >
                {/* Pricing toggle */}
                <div className="px-6 flex flex-col justify-end">
                    <div className="pb-5 md:border-b border-slate-700">
                        {/* Toggle switch */}
                        {/* <div className="max-md:text-center">
                            <div className="inline-flex items-center whitespace-nowrap">
                                <div className="text-sm text-slate-500 font-medium mr-2 md:max-lg:hidden">Monthly</div>
                                <div className="relative">
                                    <input
                                        type="checkbox"
                                        id="toggle"
                                        className="peer sr-only"
                                        checked={annual}
                                        onChange={() => setAnnual(!annual)}
                                    />
                                    <label
                                        htmlFor="toggle"
                                        className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-slate-400 px-0.5 outline-slate-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow-sm before:transition-transform before:duration-150 peer-checked:bg-purple-500 peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-purple-500"
                                    >
                                        <span className="sr-only">Pay Yearly</span>
                                    </label>
                                </div>
                                <div className="text-sm text-slate-500 font-medium ml-2">
                                    Yearly <span className="text-teal-500">(-20%)</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* Headers */}
                <TierHeader
                    tierName="الباقة الأساسية"
                    description="مناسب للشركات الناشئة الذين يرغبون باستخدام الذكاء الاصطناعي دون الحاجة للوصول إلى المميزات المتقدمة."
                    price="489"
                />
                <TierHeader
                    tierName="الباقة المتقدمة"
                    description="مناسب للشركات التي تحتاج للوصول إلى المميزات المتقدمة لتحسين الأداء وضمان الاستمرارية."
                    price="2399"
                />
                <TierHeader
                    tierName="باقة الشركات الكبرى"
                    description="مناسب للشركات الكبيرة التي تحتاج إلى أدوات الأمان والتحكم المتقدمة."
                />

                {/* Rows */}

                <SectionSeparator title="الاستخدام" />
                <FeatureRow
                    name="محادثات مع الذكاء الاصطناعي"
                    values={[
                        "20,000 رسالة",
                        <>
                            100,000 رسالة
                            <br />
                            أو 6,000 رسالة مع نموذج AI متقدم
                        </>,
                        "مخصص",
                    ]}
                    marks={[null, null, null]}
                />

                <SectionSeparator title="إدراج البيانات" />
                <FeatureRow name="إدراج النصوص" values={["", "", ""]} marks={[true, true, true]} />
                <FeatureRow name="إدراج صفحة موقع" values={["", "", ""]} marks={[true, true, true]} />
                <FeatureRow name="إدراج موقع كامل" values={["", "", ""]} marks={[false, true, true]} />
                <FeatureRow name="إدراج المستندات" values={["", "300 صفحة", "مخصص"]} marks={[false, null, null]} />

                <SectionSeparator title="إدارة البيانات" />
                <FeatureRow name="تحديث - حذف - إضافة" values={["", "", ""]} marks={[true, true, true]} />

                <SectionSeparator title="صفحة المحادثة" />
                <FeatureRow name="تخصيص ألوان الصفحة" values={["", "", ""]} marks={[true, true, true]} />
                <FeatureRow name="تخصيص رابط الصفحة" values={["", "", ""]} marks={[true, true, true]} />
                <FeatureRow name="إزالة حقوق المفهرس الذكي" values={["", "", ""]} marks={[false, false, true]} />

                <SectionSeparator title="الضمان والدعم" />
                <FeatureRow
                    name="الدعم الفني والتقني"
                    values={["أوقات العمل الرسمية", "أوقات العمل الإضافية", "7 / 24"]}
                    marks={[null, null, null]}
                />
                <FeatureRow
                    name="الاستجابة للحوادث"
                    values={["أوقات العمل الرسمية", "7 / 24", "7 / 24"]}
                    marks={[null, null, null]}
                />
                <FeatureRow
                    name="الاستشارات"
                    values={["", "استشارات مجانية للبدء والتحسين", "استشارات مجانية للبدء والتحسين"]}
                    marks={[false, null, null]}
                />
                <FeatureRow name="اتفاقية مستوى الخدمة SLA" values={["", "99%", "99%"]} marks={[false, null, null]} />

                <SectionSeparator title="أدوات الأمان والتحكم" />
                <FeatureRow name="لوحة مراقبة الأسئلة" values={["", "", ""]} marks={[false, true, true]} />
                <FeatureRow
                    name="التشغيل في بيئة خاصة"
                    values={["", "", "Virtual Private Cloud or on-premises"]}
                    marks={[false, false, null]}
                />
                <FeatureRow name="خاصية الدخول الموحد SSO" values={["", "", ""]} marks={[false, false, true]} />
                <FeatureRow name="نظام الصلاحيات RBAC" values={["", "", ""]} marks={[false, false, true]} />
                <FeatureRow
                    name="سجلات التدقيق الأمنية Security Audit Logs"
                    values={["", "", ""]}
                    marks={[false, false, true]}
                />
                <FeatureRow name="خاصية البيانات السرية" values={["", "", ""]} marks={[false, false, true]} />
            </div>
        </div>
    );
}

const TierHeader = ({ tierName, description, price }) => {
    return (
        <div className="px-6 flex flex-col justify-end">
            <div className="grow pb-4 mb-4 border-b border-slate-700">
                <div className="text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-0.5">
                    {tierName}
                </div>
                <div className="mb-1 flex items-end gap-1">
                    {price && (
                        <>
                            <span className="text-3xl font-bold text-slate-50">{price}</span>
                            <span className="text-lg font-medium text-slate-500">ريال</span>
                            <span className="text-sm text-slate-600 font-medium">\ شهر</span>
                        </>
                    )}
                    {!price && <span className="text-2xl font-bold text-slate-50">سعر مخصص</span>}
                </div>
                <div className="text-slate-400">{description}</div>
            </div>
            {/* <div className="pb-4 border-b border-slate-700">
                <a
                    className="btn-sm text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group"
                    href="#0"
                >
                    Get Started
                    <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                        -&gt;
                    </span>
                </a>
            </div> */}
        </div>
    );
};

const SectionSeparator = ({ title }) => {
    return (
        <>
            <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-50 font-medium mt-4 text-base md:text-lg">{title}</div>
            </div>
            {Array.from({ length: TOTAL_PACKAGES }).map((_, index) => (
                <div className="px-6 flex flex-col justify-end">
                    <div className="py-2 text-slate-50 font-medium mt-4 text-base md:text-lg md:hidden">{title}</div>
                </div>
            ))}
        </>
    );
};

const FeatureRow = ({ name, values = ["", 1, "Unlimited"], marks = [false, true, null] }) => {
    const XMark = () => {
        return (
            <svg
                className="shrink-0 fill-purple-500 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
            >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
        );
    };
    const CheckMark = () => {
        return (
            <svg className="shrink-0 fill-purple-500 mr-3" xmlns="http://www.w3.org/2000/svg" width="12" height="9">
                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" />
            </svg>
        );
    };

    return (
        <>
            <div className="px-6 flex flex-col justify-end">
                <div className="py-2 text-slate-400 border-b border-slate-700">{name}</div>
            </div>
            {Array.from({ length: TOTAL_PACKAGES }).map((_, index) => (
                <div className="px-6 flex flex-col justify-end">
                    <div className="flex items-center h-full border-b border-slate-700 py-2 text-slate-400">
                        <span>
                            <span className="md:hidden">{name + ": "}</span> {values[index]}
                        </span>
                        {marks[index] === true && <CheckMark />}
                        {marks[index] === false && <XMark />}
                    </div>
                </div>
            ))}
        </>
    );
};
