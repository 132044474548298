import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../components/ui/header";
import Footer from "../components/ui/footer";

export default function DefaultLayout({ children }) {
    useEffect(() => {
        AOS.init({
            once: true,
            duration: 1000,
            easing: "ease-out-cubic",
        });
    }, []);

    return (
        <>
            <Header />
            <main className="grow">{children}</main>
            <Footer />
        </>
    );
}
