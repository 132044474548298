import KSAIcon from "../../images/KSAmap.png";
import SSOIcon from "../../images/SSO.png";
import SecureDataIcon from "../../images/SecureData.png";
import RateLimitingIcon from "../../images/RateLimiting.png";
import secureConnectionIcon from "../../images/secureConnection.png";
import LocalDataIcon from "../../images/LocalData.png";

export default function Features04() {
    const rows = [
        {
            title: "التحكم والشفافية",
            features: [
                {
                    title: "إدارة المحتوى",
                    description:
                        "تحكم كامل على محتوى الذكاء الاصطناعي مع إمكانية تعديل وحذف البيانات المضافة من أي مصدر.",
                    icon: "M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zM11 4.5V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2zM4.5 8h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1zm0 2.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1zm0 2.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1z",
                    isSvg: true,
                },
                {
                    title: "رؤية كاملة",
                    description: "لوحة بيانات مفصلة عن أداء مساعدك الذكي في محادثاته ونقاط التطوير المحتملة.",
                    icon: "M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z",
                    isSvg: true,
                },
                {
                    title: "التخصيص",
                    description:
                        "يمكنك تحديد سلوك مساعدك الذكي خلال المحادثات وكذلك تغيير مظهره بما يتناسب مع استخداماتك.",
                    icon: "M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z",
                    isSvg: true,
                },
            ],
        },
        {
            title: "الأمان والحماية",
            features: [
                {
                    title: "صفحة دخول خاصة",
                    description: "تمكنك منصة المفهرس الذكي من إنشاء صفحة دخول خاصة بك للوصول إلى مساعدك الذكي.",
                    icon: "M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z M8 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z",
                    isSvg: true,
                },
                {
                    title: "الدخول الموحد SSO",
                    description:
                        "من خلال الربط مع منصة الدخول الموحد الخاصة بك مثل Active Directory أو من خلال منصات الدخول الموحد العامة مثل جوجل.",
                    icon: SSOIcon,
                    isSvg: false,
                },
                {
                    title: "إدارة الصلاحيات RBAC",
                    description: "تحكم في صلاحيات المستخدمين من بين المشغلين والإداريين والمستفيدين من المساعد الذكي.",
                    icon: "M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z",
                    isSvg: true,
                },
                {
                    title: "بيئة سحابية خاصة",
                    description: "حيث يتم تشغيل كامل المنصة في بيئة سحابية خاصة بك ومحمية من الوصول الخارجي.",
                    icon: "M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z",
                    isSvg: true,
                },
                {
                    title: "النشر المحلي on-premise",
                    description: "حيث يتم تشغيل كامل المنصة بملحقاتها في سيرفراتك الخاصة وإدارتها محليًا.",
                    icon: "M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12zM3 4v8h2V4H3zm3 0v8h2V4H6zm3 0v8h2V4H9zm3 0v8h2V4h-2z",
                    isSvg: true,
                },
                {
                    title: "سجلات التدقيق الأمنية",
                    description:
                        "تتبع كامل للعمليات والإجراءات الإدارية والتشغيلية التي تتم على المنصة لضمان أعلى مستويات الأمان.",
                    icon: "M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2z",
                    isSvg: true,
                },
                {
                    title: "الإتصال المشفر",
                    description: "يتم تشفير جميع الإتصالات بين المنصة والمستخدمين لضمان سرية البيانات.",
                    icon: secureConnectionIcon,
                    isSvg: false,
                },
                {
                    title: "خاصية البيانات السرية",
                    description:
                        "حيث تتم إدارة البيانات المخزنة على المنصة بأمان وسرية تامة لضمان عدم الوصول غير المصرح.",
                    icon: SecureDataIcon,
                    isSvg: false,
                },
                {
                    title: "التحكم في عدد الطلبات",
                    description:
                        "الحماية من سوء الاستخدام من خلال تحديد عدد الطلبات المسموح بها لكل مستخدم خلال فترة محددة.",
                    icon: RateLimitingIcon,
                    isSvg: false,
                },
            ],
        },
        {
            title: "توطين التقنية",
            features: [
                {
                    title: "تقنية سعودية",
                    description: "تم تطوير المنصة بالكامل بأيدي سعودية وباستخدام تقنيات محلية حديثة ومتطورة.",
                    icon: KSAIcon,
                    isSvg: false,
                },
                {
                    title: "خدمة من المملكة",
                    description: "يتم تقديم جميع الخدمات والدعم الفني من داخل المملكة العربية السعودية.",
                    icon: "M8 0C5.2 0 3 2.2 3 5c0 1.1.4 2.1 1 3L8 14l4-6c.6-.9 1-1.9 1-3 0-2.8-2.2-5-5-5zm0 7.5c-1.4 0-2.5-1.1-2.5-2.5S6.6 2.5 8 2.5s2.5 1.1 2.5 2.5S9.4 7.5 8 7.5z",
                    isSvg: true,
                },
                {
                    title: "خاصية البيانات المحلية",
                    description:
                        "تخزين ومعالجة جميع البيانات داخل السعودية وفقاً لمعايير الهيئة السعودية للبيانات والذكاء الاصطناعي وهيئة الاتصالات والفضاء والتقنية.",
                    icon: LocalDataIcon,
                    isSvg: false,
                },
            ],
        },
    ];

    return (
        <section className="relative">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                {/* Blurred shape */}
                <div
                    className="absolute top-0 -mt-24 right-0 -mr-16 blur-2xl opacity-70 pointer-events-none -z-10"
                    aria-hidden="true"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                        <defs>
                            <linearGradient id="bs4-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                <stop offset="0%" stopColor="#A855F7" />
                                <stop offset="100%" stopColor="#6366F1" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path
                            fill="url(#bs4-a)"
                            fillRule="evenodd"
                            d="m0 0 461 369-284 58z"
                            transform="matrix(-1 0 0 -1 434 427)"
                        />
                    </svg>
                </div>

                <div className="pt-16 pb-12 md:pt-32 md:pb-20">
                    {/* Section header */}
                    <div className="max-w-3xl pb-12 md:pb-20">
                        <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
                            لماذا المفهرس الذكي؟
                        </h2>
                        <p className="text-lg text-slate-400">{/* TODO: Add description */}</p>
                    </div>
                    {/* Rows */}
                    <div className="divide-y divide-slate-600">
                        {rows.map((row) => (
                            <FeatureRow key={row.title} title={row.title} features={row.features} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

function FeatureRow({ title, features }) {
    return (
        <div className="py-8 first-of-type:pt-0 last-of-type:pb-0">
            <div>
                <div className="inline-flex font-medium text-lg bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-200 pb-6">
                    {title}
                </div>
            </div>
            <div className="grid md:grid-cols-3 gap-8 md:gap-12 mb-2">
                {features.map(({ title: featureTitle, description, icon, isSvg }) => (
                    <div key={featureTitle}>
                        <div className="flex items-center space-x-2 mb-1">
                            {isSvg ? (
                                <svg
                                    className="shrink-0 fill-slate-300 ml-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                >
                                    <path d={icon} />
                                </svg>
                            ) : (
                                <img src={icon} className="shrink-0 invert ml-2" width="20" height="20" />
                            )}
                            <h4 className="font-medium text-slate-50">{featureTitle}</h4>
                        </div>
                        <p className="text-sm text-slate-400">{description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
