import XIcon from "../../images/X-White.svg";
import PhoneIcon from "../../images/Phone-White.svg";
import MailIcon from "../../images/Mail-White.svg";

export default function Cta() {
    return (
        <section id="contact-us">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="relative px-8 py-12 md:py-20 rounded-[3rem] overflow-hidden">
                    {/* Radial gradient */}
                    <div
                        className="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square"
                        aria-hidden="true"
                    >
                        <div className="absolute inset-0 translate-z-0 bg-purple-500 rounded-full blur-[120px] opacity-70" />
                        <div className="absolute w-1/4 h-1/4 translate-z-0 bg-purple-400 rounded-full blur-[40px]" />
                    </div>
                    {/* Blurred shape */}
                    <div
                        className="absolute bottom-0 translate-y-1/2 left-0 blur-2xl opacity-50 pointer-events-none -z-10"
                        aria-hidden="true"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                            <defs>
                                <linearGradient id="bs5-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                    <stop offset="0%" stopColor="#A855F7" />
                                    <stop offset="100%" stopColor="#6366F1" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                            <path
                                fill="url(#bs5-a)"
                                fillRule="evenodd"
                                d="m0 0 461 369-284 58z"
                                transform="matrix(1 0 0 -1 0 427)"
                            />
                        </svg>
                    </div>
                    {/* Content */}
                    <div className="max-w-3xl mx-auto text-center">
                        <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
                            تواصل معنا
                        </h2>
                        <p className="text-lg text-slate-400 mb-8">يشرفنا تواصلك معنا على أي من المنصات التالية:</p>
                        <div className="flex flex-row w-full justify-around align-center">
                            <a href="https://x.com/InteliDexer" target="_blank">
                                <img className="md:h-10 h-8" src={XIcon} />
                            </a>
                            <a href="https://wa.me/+966565921900" target="_blank">
                            <img className="md:h-10 h-8" src={PhoneIcon} />
                            </a>
                            <a href="mailto:contact@intelidexer.com" target="_blank">
                                <img className="md:h-10 h-8" src={MailIcon} />
                            </a>
                            {/* <Form /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
function Form() {
    return (
        <div className="mx-auto">
            <form>
                <div className="md:grid md:grid-cols-2 gap-32">
                    <div className="flex flex-col">
                        <h4 className="font-bold text-lg mb-4">Contact Details</h4>
                        <div className="mb-4">
                            <label className="text-slate-400 font-medium mb-1" htmlFor="name">
                                Name
                            </label>
                            <input
                                id="name"
                                className="border border-transparent rounded-full py-1 px-4 w-full focus:outline-none hover:border-blue-300 focus:border-blue-300 bg-slate-200 text-purple-700 placeholder-slate-400 form-input w-full"
                                type="text"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="text-slate-400 mb-1" htmlFor="email">
                                Email
                            </label>
                            <input
                                id="email"
                                className="border border-transparent rounded-full py-1 px-4 w-full focus:outline-none hover:border-blue-300 focus:border-blue-300 bg-slate-200 text-purple-700 placeholder-slate-400 form-input w-full"
                                type="email"
                                placeholder="markrossi@company.com"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="text-slate-400 font-medium mb-1" htmlFor="phone">
                                Phone
                            </label>
                            <input
                                id="phone"
                                className="border border-transparent rounded-full py-1 px-4 w-full focus:outline-none hover:border-blue-300 focus:border-blue-300 bg-slate-200 text-purple-700 placeholder-slate-400 form-input w-full"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <h4 className="font-bold text-lg mb-4">Event Details:</h4>
                        <div className="mb-4">
                            <label className="text-slate-400 mb-1" htmlFor="name">
                                Event Name
                            </label>
                            <input
                                id="event-name"
                                className="border border-transparent rounded-full py-1 px-4 w-full focus:outline-none hover:border-blue-300 focus:border-blue-300 bg-slate-200 text-purple-700 placeholder-slate-400 form-input w-full"
                                type="text"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="text-slate-400 mb-1" htmlFor="size">
                                Size
                            </label>
                            <select
                                id="referrer"
                                className="border border-transparent rounded-full py-1 px-4 w-full focus:outline-none hover:border-blue-300 focus:border-blue-300 bg-slate-200 text-purple-700 placeholder-slate-400 form-input w-full"
                            >
                                <option className="bg-slate-400" value="" disabled selected hidden>
                                    Select event size
                                </option>
                                <option>Less than 1,000 attendees</option>
                                <option>1,000 to 10,000 attendees</option>
                                <option>10,000 to 100,000 attendees</option>
                                <option>More than 100,000 attendees</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="text-slate-400 mb-1" htmlFor="location">
                                Location
                            </label>
                            <input
                                id="location"
                                className="border border-transparent rounded-full py-1 px-4 w-full focus:outline-none hover:border-blue-300 focus:border-blue-300 bg-slate-200 text-purple-700 placeholder-slate-400 form-input w-full"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-6">
                    <button className="w-1/3 btn text-sm text-white bg-purple-500 hover:bg-purple-600 shadow-sm group">
                        Submit
                        <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                            -&gt;
                        </span>
                    </button>
                </div>
            </form>
        </div>
    );
}
