import React, { useState, useEffect } from "react";
import Hero from "../components/Sections/hero2";
import Clients from "../components/clients";
import Features from "../components/Sections/features";
import Features02 from "../components/Sections/features-02";
import Features03 from "../components/Sections/features-03";
import TestimonialsCarousel from "../components/testimonials-carousel";
import Features04 from "../components/Sections/features-04";
import Testimonials from "../components/testimonials";
import Cta from "../components/Sections/cta";
import Header from "../components/ui/header";
import ScrollIndicator from "../components/Elements/ScrollIndicator";

export default function Home() {
    const [isTop, setIsTop] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            setIsTop(window.scrollY < 100);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <>
            <Hero />
            <ScrollIndicator isVisible={isTop} delay={3000} />
            <Clients />
            <Features />
            <Features02 />
            {/*<Features03 />*/}
            {/* <TestimonialsCarousel /> */}
            <Features04 />
            {/* <Testimonials /> */}
            <Cta />
        </>
    );
}
