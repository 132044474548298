import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { smoothScrollTo } from "../utils/smoothScrollTo";

export default function MobileMenu() {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const trigger = useRef(null);
    const mobileNav = useRef(null);

    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!mobileNav.current || !trigger.current) return;
            if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    return (
        <div className="md:hidden flex items-center ml-4">
            {/* Hamburger button */}
            <button
                ref={trigger}
                className={`hamburger ${mobileNavOpen && "active"}`}
                aria-controls="mobile-nav"
                aria-expanded={mobileNavOpen}
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
                <span className="sr-only">Menu</span>
                <svg
                    className="w-5 h-5 fill-current text-slate-300 hover:text-white transition duration-150 ease-in-out"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect y="2" width="20" height="2" rx="1" />
                    <rect y="9" width="20" height="2" rx="1" />
                    <rect y="16" width="20" height="2" rx="1" />
                </svg>
            </button>

            {/*Mobile navigation */}
            <nav
                id="mobile-nav"
                ref={mobileNav}
                className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
                style={
                    mobileNavOpen
                        ? {
                              maxHeight: mobileNav.current?.scrollHeight,
                              opacity: 1,
                          }
                        : { maxHeight: 0, opacity: 0.8 }
                }
            >
                <ul className="border border-transparent [background:linear-gradient(315deg,_rgb(40,_20,_70)_20%,_theme(colors.purple.900)_80%,_theme(colors.purple.800)_95%,_theme(colors.purple.700)_100%)_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] rounded-lg px-4 py-1.5">
                    <li>
                        <Link className="flex font-medium text-sm text-slate-300 hover:text-white py-1.5" to="/">
                            الرئيسية
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="flex font-medium text-sm text-slate-300 hover:text-white py-1.5"
                            to="/"
                            onClick={() => smoothScrollTo("features", 1000)}
                        >
                            ما يميزنا
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="flex font-medium text-sm text-slate-300 hover:text-white py-1.5"
                            to="/"
                            onClick={() => smoothScrollTo("how-to-use", 1000)}
                        >
                            كيف يعمل؟
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="flex font-medium text-sm text-slate-300 hover:text-white py-1.5"
                            to="/"
                            onClick={() => smoothScrollTo("contact-us", 1000)}
                        >
                            تواصل معنا
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="flex font-medium text-sm text-slate-300 hover:text-white py-1.5"
                            to="/home/pricing"
                        >
                            الأسعار
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
