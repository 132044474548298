import { Link } from "react-router-dom";
import Logo from "./logo";
import MobileMenu from "./mobile-menu";
import { smoothScrollTo } from "../utils/smoothScrollTo";

export default function Header() {
    return (
        <header className="absolute w-full z-30">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between h-16 md:h-20">
                    <MobileMenu />

                    {/* Desktop sign in links */}
                    {/* <ul className="flex-1 flex justify-start items-center">
                        <li className="ml-6">
                            <a
                                className="btn-sm py-0.5 text-slate-300 hover:text-white transition duration-150 ease-in-out group 
                                    [background:linear-gradient(theme(colors.purple.800),_theme(colors.purple.600))_padding-box,_conic-gradient(#94a3b8,#334155_25%,#334155_75%,#94a3b8_100%)_border-box] 
                                    relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                                href="/signin"
                            >
                                <span className="relative inline-flex items-center text-lg">
                                    <span className="ml-2">تسجيل الدخول</span>
                                </span>
                            </a>
                        </li>
                    </ul> */}

                    {/* Desktop navigation */}
                    <nav className="hidden md:flex md:grow">
                        {/* Desktop menu links */}
                        <ul className="flex grow justify-start flex-wrap items-center">
                            <li>
                                <Link
                                    className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                                    to="/"
                                >
                                    الرئيسية
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                                    to="/"
                                    onClick={() => smoothScrollTo("features", 1000)}
                                >
                                    ما يميزنا
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                                    to="/"
                                    onClick={() => smoothScrollTo("how-to-use", 1000)}
                                >
                                    كيف يعمل؟
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                                    to="/"
                                    onClick={() => smoothScrollTo("contact-us", 1000)}
                                >
                                    تواصل معنا
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                                    to="/home/pricing"
                                >
                                    الأسعار
                                </Link>
                            </li>
                        </ul>
                    </nav>

                    {/* Site branding */}
                    <div className="flex-1 flex justify-end items-center">
                        <Link to="/">
                            <Logo long={false} icon={false} width={150} />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}
