import Eclipse from "../Elements/Eclipse";
import GlowingGridContainer from "../Elements/GlowingGridContainer";
import SelectionButton from "../Elements/SelectionButton";
import SelectionContextProvider from "../Elements/SelectionContextProvider";
import Particles from "../particles";

import Whatsapp from "../../images/Whatsapp.png";
import Website from "../../images/Website.png";
import X from "../../images/X.png";
import Email from "../../images/Email.png";

import Whats_Icon from "../../images/Whats_Icon.png";
import X_Icon from "../../images/X_Icon.png";
import Web_Icon from "../../images/Web_Icon.png";
import Email_Icon from "../../images/Email_Icon.png";



export default function Features() {
    return (
        <section id="features" className="">
            <SelectionContextProvider>
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6">
                    {/* Illustration */}
                    <Eclipse position="top" eclipseColor="white" flipped="false" width={1400} />
                    <div className="pt-16 pb-12 md:pt-52 md:pb-20">
                        <div>
                            {/* Section content */}
                            <div className="max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-8 space-y-reverse md:space-y-0">
                                {/* Content */}
                                <div
                                    className="md:w-7/12 lg:w-1/2 order-1 md:order-none max-md:text-center"
                                    data-aos="fade-down"
                                >
                                    {/* Content #1 */}
                                    <div>
                                        {/* <div className="inline-flex bg-clip-text text-transparent bg-gradient-to-l from-[#b11f66] to-[#e1a5c2] pb-3 text-xl font-bold">
                                            سـهـل الإنـشـاء، مـتـقـدم الـخـصـائـص
                                        </div> */}
                                    </div>
                                    <h3 className="h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                                    مساعدك الذكي لخدمة <span className="inline-flex bg-clip-text text-transparent bg-gradient-to-r from-purple-200 to-purple-500">عملاءك</span>
                                    </h3>
                                    <p className="text-lg text-slate-400 mb-8">
                                    مساعد ذكي يتكامل مع منصات التواصل المختلفة ليقدم خدمة عملاء فورية على مدار الساعة
                                    </p>
                                    <div className="mt-8 max-w-xs max-md:mx-auto space-y-2">
                                        <SelectionButton index="0">
                                            <div className="w-8 h-8 shrink-0 mr-3 flex items-center justify-center">
                                                <img
                                                    src={Web_Icon}
                                                    alt="Icon 1"
                                                    className="max-w-full max-h-full object-contain"
                                                />
                                            </div>
                                            <span className="text-lg">الموقع الإلكتروني </span>
                                        </SelectionButton>
                                        <SelectionButton index="1">
                                            <div className="w-8 h-8 shrink-0 mr-3 flex items-center justify-center">
                                                <img
                                                    src={Email_Icon}
                                                    alt="Icon 2"
                                                    className="max-w-full max-h-full object-contain"
                                                />
                                            </div>
                                            <span className="text-lg">البريد الإلكتروني</span>
                                        </SelectionButton>
                                        <SelectionButton index="2">
                                            <div className="w-8 h-8 shrink-0 mr-3 flex items-center justify-center">
                                                <img
                                                    src={Whats_Icon}
                                                    alt="Icon 3"
                                                    className="max-w-full max-h-full object-contain"
                                                />
                                            </div>
                                            <span className="text-lg">تطبيق الواتساب</span>
                                        </SelectionButton>
                                        <SelectionButton index="3">
                                            <div className="w-8 h-8 shrink-0 mr-3 flex items-center justify-center">
                                                <img
                                                    src={X_Icon}
                                                    alt="Icon 4"
                                                    className="max-w-full max-h-full object-contain"
                                                />
                                            </div>
                                            <span className="text-lg">منصة X</span>
                                        </SelectionButton>
                                        {/* <SelectionButton index="4">
                                            <div className="w-8 h-8 shrink-0 mr-3 flex items-center justify-center">
                                                <img
                                                    src={Minutes_Icon}
                                                    alt="Icon 4"
                                                    className="max-w-full max-h-full object-contain"
                                                />
                                            </div>
                                            <span className="text-lg">جاهز للعمل خلال دقائق</span>
                                        </SelectionButton> */}
                                    </div>
                                </div>

                                {/* Image */}
                                <div className="md:w-5/12 lg:w-1/2 m-0" data-aos="fade-up" data-aos-delay="100">
                                    <div className="relative py-24 -mt-12">
                                        {/* Particles animation */}
                                        <Particles className="absolute inset-0 -z-10" quantity={8} staticity={30} />

                                        <GlowingGridContainer>
                                            <img src={Website} />
                                            <img src={Email} />
                                            <img src={Whatsapp} />
                                            <img src={X} />
                                            <svg
                                                className="relative fill-slate-200"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="26"
                                                height="14"
                                            >
                                                <path
                                                    fillRule="nonzero"
                                                    d="m10 5.414-8 8L.586 12 10 2.586l6 6 8-8L25.414 2 16 11.414z"
                                                />
                                            </svg>
                                        </GlowingGridContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SelectionContextProvider>
        </section>
    );
}
