import Particles from "../particles";
import Highlighter, { HighlighterItem } from "../highlighter";
import { smoothScrollTo } from "../utils/smoothScrollTo";

import FeatureImg01 from "../../images/steps_create_project.png";
import FeatureImg02 from "../../images/steps_ingest_data2.png";
import FeatureImg03 from "../../images/steps_chat_page.png";
import tutorialVideo from "../../images/tutorial.mp4";

export default function Features02() {
    return (
        <section id="how-to-use" className="relative">
            {/* Particles animation */}
            <div className="absolute left-1/2 -translate-x-1/2 top-0 -z-10 w-80 h-80 -mt-24 -ml-32">
                <Particles className="absolute inset-0 -z-10" quantity={6} staticity={30} />
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-16 md:pt-32">
                    {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-6">
                            مساعدك الذكي لتمكين <span className="inline-flex bg-clip-text text-transparent bg-gradient-to-r from-purple-200 to-purple-500">فريقك</span>
                        </h2>
                        <p className="text-lg text-slate-400 leading-relaxed tracking-wide font-medium">
                            زد كفاءة فريقك مع مساعد ذكي يتيح الوصول للمعلومات والبيانات من خلال محادثة مباشرة وآمنة
                        </p>
                    </div>

                    {/* Highlighted boxes */}
                    <div className="relative pb-12 md:pb-20">
                        {/* Blurred shape */}
                        <div
                            className="absolute bottom-0 -mb-20 left-1/2 -translate-x-1/2 blur-2xl opacity-50 pointer-events-none"
                            aria-hidden="true"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                                <defs>
                                    <linearGradient id="bs2-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                        <stop offset="0%" stopColor="#6366F1" />
                                        <stop offset="100%" stopColor="#6366F1" stopOpacity="0" />
                                    </linearGradient>
                                </defs>
                                <path
                                    fill="url(#bs2-a)"
                                    fillRule="evenodd"
                                    d="m346 898 461 369-284 58z"
                                    transform="translate(-346 -898)"
                                />
                            </svg>
                        </div>
                        {/* Grid */}
                        <Highlighter className="grid md:grid-cols-12 gap-6 group">
                            {/* Box #1 */}
                            <div className="md:col-span-12" data-aos="fade-down">
                                <HighlighterItem blurredShape radialGradient>
                                    <div className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden border-2 border-purple-500/30 shadow-lg shadow-purple-500/20">
                                        {/* Radial gradient */}
                                        <div
                                            className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                            aria-hidden="true"
                                        >
                                            <div className="absolute inset-0 translate-z-0 bg-purple-500 rounded-full blur-[120px]" />
                                        </div>
                                        <div className="p-1 bg-gradient-to-r from-purple-500/20 via-purple-500/30 to-purple-500/20 rounded-[inherit]">
                                            <div className="relative">
                                                <video
                                                    src={tutorialVideo}
                                                    width={1920}
                                                    height={1080}
                                                    controls
                                                    muted
                                                    playsInline
                                                    className="object-cover w-full h-full rounded-[inherit]"
                                                    ref={(videoElement) => {
                                                        if (videoElement) {
                                                            setTimeout(() => {
                                                                videoElement.play();
                                                            }, 2000);
                                                            
                                                            const observer = new IntersectionObserver(
                                                                (entries) => {
                                                                    entries.forEach((entry) => {
                                                                        if (entry.isIntersecting) {
                                                                            videoElement.play();
                                                                        } else {
                                                                            videoElement.pause();
                                                                        }
                                                                    });
                                                                },
                                                                { threshold: 0.1 }
                                                            );
                                                            observer.observe(videoElement);
                                                        }
                                                    }}
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-700">
                                                    <div className="absolute h-full bg-purple-500" style={{width: "0%"}} id="progress-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </HighlighterItem>
                            </div>

                            {/* Box #2 - أنشئ مشروع جديد - Commented out original section */}
                            {/* <div className="md:col-span-12" data-aos="fade-down">
                                <HighlighterItem blurredShape radialGradient>
                                    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                        <div style={{ perspective: "1000px", perspectiveOrigin: "50% 50%" }} className="relative w-full h-auto md:h-auto overflow-hidden md:overflow-visible">
                                            <img className="img-3d right-3d" src={FeatureImg01} alt="Feature 01" />
                                        </div>
                                        <div className="md:w-1/2 shrink-0 order-1 md:order-none p-6 md:p-10">
                                            <div className="mb-5">
                                                <div>
                                                    <h3 className="pb-6 inline-flex text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                                                        أنشئ مشروع جديد
                                                    </h3>
                                                    <p className="text-slate-400 text-lg">
                                                        قم بتعبئة نموذج بسيط لتأسيس مساعدك الشخصي أو موظف خدمة العملاء..
                                                        حدد اسم ومجال العمل ووسيلة التواصل.. بالإضافة إلى إمكانية اختيار
                                                        النموذج المناسب للمهمة من عدة نماذج مطروحة سواء كنت تبحث عن سرعة
                                                        الرد أم رد أدق
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{ perspective: "1000px", perspectiveOrigin: "50% 50%" }}
                                            className="relative w-full h-auto md:h-auto overflow-hidden md:overflow-visible"
                                        >
                                            <img className="img-3d" src={FeatureImg01} alt="Feature 01" />
                                        </div>
                                    </div>
                                </HighlighterItem>
                            </div> */}

                            {/* <div className="md:col-span-12" data-aos="fade-down">
                                <HighlighterItem blurredShape radialGradient>
                                    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                        <div
                                            style={{ perspective: "1000px", perspectiveOrigin: "50% 50%" }}
                                            className="relative w-full h-auto md:h-auto overflow-hidden md:overflow-visible"
                                        >
                                            <img className="img-3d right-3d" src={FeatureImg02} alt="Feature 02" />
                                        </div>
                                        <div className="md:w-1/2 shrink-0 order-1 md:order-none p-6 md:p-10 m-4">
                                            <div className="mb-5">
                                                <div>
                                                    <h3 className="pb-6 inline-flex text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                                                        أضف البيانات والمعلومات
                                                    </h3>
                                                    <p className="text-slate-400 text-lg">
                                                        قم بإضافة البيانات والمعلومات على هيئة مستندات أو مواقع أو
                                                        نصوص.. لا حاجة لإعداد قائمة الأسئلة الشائعة سيقوم الذكاء
                                                        الاصطناعي التوليدي بالعمل كاملًا !!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </HighlighterItem>
                            </div> */}

                            {/* <div className="md:col-span-12" data-aos="fade-down">
                                <HighlighterItem blurredShape radialGradient>
                                    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                        <div className="md:w-1/2 shrink-0 order-1 md:order-none p-6 md:p-10 m-4">
                                            <div className="mb-5">
                                                <div>
                                                    <h3 className="pb-6 inline-flex text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                                                        تكوين صفحة الدردشة
                                                    </h3>
                                                    <p className="text-slate-400 text-lg">
                                                        كون صفحة دردشة مخصصة لك بألوانك واسمك وشعارك.. يمكنك إدراج
                                                        الصفحة تحت رابط المنصة - مثلا MyProject.InteliDexer.com - أو تحت
                                                        رابطك الخاص في حال توفر لك ذلك - مثلا chat.myproject.com
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{ perspective: "1000px", perspectiveOrigin: "50% 50%" }}
                                            className="relative w-full h-auto md:h-auto overflow-hidden md:overflow-visible"
                                        >
                                            <img className="img-3d" src={FeatureImg03} alt="Feature 03" />
                                        </div>
                                    </div>
                                </HighlighterItem>
                            </div> */}
                            {/* Box #2 */}
                            {/* <div className="md:col-span-5" data-aos="fade-down">
                                <HighlighterItem>
                                    <div className="flex flex-col">
                                        <div className="md:max-w-[480px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8">
                                            <div>
                                                <h3 className="pb-6 inline-flex text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                                                    Fully managed cloud service
                                                </h3>
                                                <p className="text-slate-400">
                                                    We manage everything. You only need to provide the data.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                                                <img
                                                    className="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0"
                                                    src={FeatureImg02}
                                                    width={536}
                                                    height={230}
                                                    alt="Feature 02"
                                                />
                                            </div>
                                    </div>
                                </HighlighterItem>
                            </div> */}
                            {/* Box #3 */}
                            {/* <div className="md:col-span-7" data-aos="fade-down">
                                <HighlighterItem>
                                    <div className="flex flex-col">
                                        <div className="md:max-w-[680px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8">
                                            <div>
                                                <h3 className="pb-6 inline-flex text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                                                    Customizability
                                                </h3>
                                                <p className="text-slate-400">
                                                    Quickly apply any customization on the chatbot User Interface or
                                                    integrate with the streamlined Conf-Bot REST API.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                                                <img
                                                    className="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0"
                                                    src={FeatureImg03}
                                                    width={230}
                                                    height={230}
                                                    alt="Feature 03"
                                                />
                                            </div>
                                    </div>
                                </HighlighterItem>
                            </div> */}
                        </Highlighter>
                    </div>
                    {/* <FeatureList /> */}
                </div>
            </div>
        </section>
    );
}

function FeatureList() {
    return (
        <div className="grid md:grid-cols-3 gap-8 md:gap-12">
            {/* Feature */}
            <div>
                <div className="flex items-center space-x-2 mb-1">
                    <svg className="shrink-0 fill-slate-300" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path d="M7.999 2.34a4.733 4.733 0 0 0-6.604 6.778l5.904 5.762a1 1 0 0 0 1.4 0l5.915-5.77a4.733 4.733 0 0 0-6.615-6.77Zm5.208 5.348-5.208 5.079-5.2-5.07a2.734 2.734 0 0 1 3.867-3.864c.182.19.335.404.455.638a1.04 1.04 0 0 0 1.756 0 2.724 2.724 0 0 1 5.122 1.294 2.7 2.7 0 0 1-.792 1.923Z" />
                    </svg>
                    <h4 className="font-medium text-slate-50">Filters</h4>
                </div>
                <p className="text-sm text-slate-400">
                    Login box must find the right balance for the user convenience, privacy and security.
                </p>
            </div>
            {/* Feature */}
            <div>
                <div className="flex items-center space-x-2 mb-1">
                    <svg className="shrink-0 fill-slate-300" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z" />
                    </svg>
                    <h4 className="font-medium text-slate-50">Configurable</h4>
                </div>
                <p className="text-sm text-slate-400">
                    Login box must find the right balance for the user convenience, privacy and security.
                </p>
            </div>
            {/* Feature */}
            <div>
                <div className="flex items-center space-x-2 mb-1">
                    <svg className="shrink-0 fill-slate-300" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path d="M14 0a2 2 0 0 1 2 2v4a1 1 0 0 1-2 0V2H2v12h4a1 1 0 0 1 0 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12Zm-1.957 10.629 3.664 3.664a1 1 0 0 1-1.414 1.414l-3.664-3.664-.644 2.578a.5.5 0 0 1-.476.379H9.5a.5.5 0 0 1-.48-.362l-2-7a.5.5 0 0 1 .618-.618l7 2a.5.5 0 0 1-.017.965l-2.578.644Z" />
                    </svg>
                    <h4 className="font-medium text-slate-50">Adaptable</h4>
                </div>
                <p className="text-sm text-slate-400">
                    Login box must find the right balance for the user convenience, privacy and security.
                </p>
            </div>
            {/* Feature */}
            <div>
                <div className="flex items-center space-x-2 mb-1">
                    <svg className="shrink-0 fill-slate-300" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path d="M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8ZM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1Z" />
                    </svg>
                    <h4 className="font-medium text-slate-50">Authorization</h4>
                </div>
                <p className="text-sm text-slate-400">
                    Login box must find the right balance for the user convenience, privacy and security.
                </p>
            </div>
            {/* Feature */}
            <div>
                <div className="flex items-center space-x-2 mb-1">
                    <svg className="shrink-0 fill-slate-300" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12Zm0 14V2H2v12h12Zm-3-7H5a1 1 0 1 1 0-2h6a1 1 0 0 1 0 2Zm0 4H5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2Z" />
                    </svg>
                    <h4 className="font-medium text-slate-50">Management</h4>
                </div>
                <p className="text-sm text-slate-400">
                    Login box must find the right balance for the user convenience, privacy and security.
                </p>
            </div>
            {/* Feature */}
            <div>
                <div className="flex items-center space-x-2 mb-1">
                    <svg className="shrink-0 fill-slate-300" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path d="M14.574 5.67a13.292 13.292 0 0 1 1.298 1.842 1 1 0 0 1 0 .98C15.743 8.716 12.706 14 8 14a6.391 6.391 0 0 1-1.557-.2l1.815-1.815C10.97 11.82 13.06 9.13 13.82 8c-.163-.243-.39-.56-.669-.907l1.424-1.424ZM.294 15.706a.999.999 0 0 1-.002-1.413l2.53-2.529C1.171 10.291.197 8.615.127 8.49a.998.998 0 0 1-.002-.975C.251 7.29 3.246 2 8 2c1.331 0 2.515.431 3.548 1.038L14.293.293a.999.999 0 1 1 1.414 1.414l-14 14a.997.997 0 0 1-1.414 0ZM2.18 8a12.603 12.603 0 0 0 2.06 2.347l1.833-1.834A1.925 1.925 0 0 1 6 8a2 2 0 0 1 2-2c.178 0 .348.03.512.074l1.566-1.566C9.438 4.201 8.742 4 8 4 5.146 4 2.958 6.835 2.181 8Z" />
                    </svg>
                    <h4 className="font-medium text-slate-50">Building</h4>
                </div>
                <p className="text-sm text-slate-400">
                    Login box must find the right balance for the user convenience, privacy and security.
                </p>
            </div>
        </div>
    );
}