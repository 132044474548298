import Particles from "./particles";

import Client01 from "../images/client-robotacademy.png";
import Client02 from "../images/client-ntdp.png";
import Client03 from "../images/client-code.png";
import Client04 from "../images/client-misk.png";
import Client05 from "../images/client-ghoroos.png";
import Client06 from "../images/client-ssn.png";

const logos = [
    { src: Client01, alt: "Robot Academy" },
    { src: Client02, alt: "NTDP" },
    { src: Client03, alt: "Code" },
    { src: Client04, alt: "Misk" },
    { src: Client05, alt: "Ghoroos" },
    { src: Client06, alt: "Ssn" },
];

export default function Clients() {
    return (
        <section data-aos="fade-right" data-aos-delay="2400">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                {/* Particles animation */}
                <div className="absolute inset-0 max-w-6xl mx-auto px-4 sm:px-6">
                    <Particles className="absolute inset-0 -z-10" quantity={5} />
                </div>

                <div className="py-10 md:py-12">
                    <div className="overflow-hidden">
                        <div className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
                            <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-16 [&_img]:max-h-[8vh] [&_img]:md:max-h-[6vh]">
                                {logos.map((logo, index) => (
                                    <li key={index}>
                                        <img src={logo.src} alt={logo.alt} />
                                    </li>
                                ))}
                            </ul>
                            <ul
                                className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-16 [&_img]:max-h-[8vh] [&_img]:md:max-h-[6vh]"
                                aria-hidden="true"
                            >
                                {logos.map((logo, index) => (
                                    <li key={index}>
                                        <img src={logo.src} alt={logo.alt} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
