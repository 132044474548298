// import Eclipse from "../Elements/Eclipse";
import Particles from "../particles";
import { smoothScrollTo } from "../utils/smoothScrollTo";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Hero() {
    // Update state to include typing status and current display text
    const [displayText, setDisplayText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentWord, setCurrentWord] = useState(0);
    const words = ["عملاؤك", "فريقك"];

    useEffect(() => {
        const typingSpeed = 250; // Speed for typing
        const deletingSpeed = 125; // Speed for deleting
        const pauseTime = 1000; // Time to pause at complete word

        const handleTyping = () => {
            const currentFullWord = words[currentWord];

            if (!isDeleting) {
                // Typing
                if (displayText !== currentFullWord) {
                    setDisplayText(currentFullWord.substring(0, displayText.length + 1));
                } else {
                    // Pause before starting to delete
                    setTimeout(() => setIsDeleting(true), pauseTime);
                    return;
                }
            } else {
                // Deleting
                if (displayText === "") {
                    setIsDeleting(false);
                    setCurrentWord((prev) => (prev + 1) % words.length);
                    return;
                }
                setDisplayText(currentFullWord.substring(0, displayText.length - 1));
            }
        };

        const timeout = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);

        return () => clearTimeout(timeout);
    }, [displayText, isDeleting, currentWord]);

    return (
        <section id="hero" data-aos="fade-down" data-aos-delay="400">
            <div
                className="flex flex-col md:flex-row gap-20 items-start md:items-center justify-start md:justify-center relative max-w-7xl mx-auto px-4 sm:px-6 pt-[10rem]"
                style={{
                    height: "auto",
                }}
            >
                <Particles className="absolute inset-0 -z-10" />
                {/* <Eclipse position="bottom" eclipseColor="blue" flipped="false" width={2146} /> */}

                {/* Hero content */}
                <div className="flex-1 flex flex-col w-full max-w-6xl mx-auto text-right justify-center align-center items-right">
                    {/* <Logo className="mb-10" dark={false} width={350} dataAos="fade-down" dataAosDelay="200" /> */}
                    <h1 className="text-2xl font-bold text-slate-300 mb-5 h-[70px]">
                        مساعد ذكي (AI Assistant) يخدم{" "}
                        <span className="inline-flex bg-clip-text text-transparent bg-gradient-to-r from-purple-200 to-purple-500">
                            {displayText}
                            <span className="cursor">|</span>
                        </span>
                    </h1>
                    <p className="pt-6 text-xl text-slate-300 mb-1" data-aos="fade-down" data-aos-delay="800">
                        <svg
                            className="w-5 h-5 inline"
                            fill="none"
                            stroke="rgb(168 85 247)"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                            />
                        </svg>{" "}
                        يفهم بيانات شركتك
                        {/* يخدم عملاءك وفريقك تلقائيًا{" "}
                        <svg
                            className="w-5 h-5 inline"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                            />
                        </svg> */}
                    </p>
                    <p className="text-xl text-slate-300 mb-1" data-aos="fade-down" data-aos-delay="900">
                        <svg
                            className="w-5 h-5 inline"
                            fill="none"
                            stroke="rgb(168 85 247)"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>{" "}
                        جاهز للعمل في دقائق
                    </p>
                    <p className="text-xl text-slate-300 mb-10" data-aos="fade-down" data-aos-delay="1000">
                        <svg
                            className="w-5 h-5 inline"
                            fill="none"
                            stroke="rgb(168 85 247)"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                            />
                        </svg>{" "}
                        بدون برمجة
                    </p>
                    <div className="mb-6" data-aos="fade-down" data-aos-delay="1200">
                        <Link
                            className="btn-sm py-0.5 text-slate-300 hover:text-white transition duration-150 ease-in-out group cursor-pointer
                                [background:linear-gradient(theme(colors.purple.800),_theme(colors.purple.600))_padding-box,_conic-gradient(#94a3b8,#334155_25%,#334155_75%,#94a3b8_100%)_border-box] 
                                relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                            onClick={() => smoothScrollTo("contact-us", 1000)}
                        >
                            <span className="relative inline-flex items-center h-6">
                                <span className="ml-2">تواصل معنا</span>
                                <span className="tracking-normal text-purple-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1 text-xl">
                                    &gt;
                                </span>
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="flex-1 flex flex-col mb-5 max-w-6xl mx-auto text-center justify-center align-center items-center">
                    <div className="relative w-full h-full bg-slate-900 rounded-3xl z-20 overflow-hidden border-2 border-purple-500/30 shadow-lg shadow-purple-500/20">
                        <div className="p-1 bg-gradient-to-r from-purple-500/20 via-purple-500/30 to-purple-500/20 rounded-[inherit]">
                            <iframe
                                src="https://chat.intelidexer.com?hideLayout=true&transparent=true&smallInput=true"
                                title="Chat"
                                style={{
                                    width: "100%",
                                    height: "400px",
                                    border: "none",
                                }}
                                sandbox="allow-scripts allow-same-origin allow-popups"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
