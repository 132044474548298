import React, { useRef, useState, useEffect } from "react";
import MousePosition from "./utils/mouse-position";

export default function Highlighter({ children, className = "", refresh = false }) {
    const containerRef = useRef(null);
    const mousePosition = MousePosition();
    const mouse = useRef({ x: 0, y: 0 });
    const containerSize = useRef({ w: 0, h: 0 });
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        containerRef.current && setBoxes(Array.from(containerRef.current.children).map((el) => el));
    }, []);

    useEffect(() => {
        initContainer();
        window.addEventListener("resize", initContainer);

        return () => {
            window.removeEventListener("resize", initContainer);
        };
    }, [setBoxes]);

    useEffect(() => {
        onMouseMove();
    }, [mousePosition]);

    useEffect(() => {
        initContainer();
    }, [refresh]);

    const initContainer = () => {
        if (containerRef.current) {
            containerSize.current.w = containerRef.current.offsetWidth;
            containerSize.current.h = containerRef.current.offsetHeight;
        }
    };

    const onMouseMove = () => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const { w, h } = containerSize.current;
            const x = mousePosition.x - rect.left;
            const y = mousePosition.y - rect.top;
            const inside = x < w && x > 0 && y < h && y > 0;
            if (inside) {
                mouse.current.x = x;
                mouse.current.y = y;
                boxes.forEach((box) => {
                    const boxX = -(box.getBoundingClientRect().left - rect.left) + mouse.current.x;
                    const boxY = -(box.getBoundingClientRect().top - rect.top) + mouse.current.y;
                    box.style.setProperty("--mouse-x", `${boxX}px`);
                    box.style.setProperty("--mouse-y", `${boxY}px`);
                });
            }
        }
    };

    return (
        <div className={className} ref={containerRef}>
            {children}
        </div>
    );
}

export function HighlighterItem({ children, blurredShape = false, radialGradient = false, className = "" }) {
    return (
        <div
            className={`relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-purple-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden ${className}`}
        >
            <div className="relative h-full bg-[rgb(40,20,70)] rounded-[inherit] z-20 overflow-hidden">
                {blurredShape && (
                    <div className="absolute right-0 top-0 blur-2xl" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="342" height="393">
                            <defs>
                                <linearGradient id="bs-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                    <stop offset="0%" stopColor="#6366F1" />
                                    <stop offset="100%" stopColor="#6366F1" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                            <path
                                fill="url(#bs-a)"
                                fillRule="evenodd"
                                d="m104 .827 461 369-284 58z"
                                transform="translate(0 -112.827)"
                                opacity=".7"
                            />
                        </svg>
                    </div>
                )}
                {radialGradient && (
                    <div
                        className="absolute flex items-center justify-center bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 h-full aspect-square"
                        aria-hidden="true"
                    >
                        <div className="absolute inset-0 translate-z-0 bg-purple-500 rounded-full blur-[120px] opacity-70" />
                        <div className="absolute w-1/4 h-1/4 translate-z-0 bg-purple-400 rounded-full blur-[40px]" />
                    </div>
                )}
                {children}
            </div>
        </div>
    );
}

export function HighlighterItem02({ children, className = "" }) {
    return (
        <div
            className={`relative h-full bg-slate-800 rounded-3xl p-px -m-px before:absolute before:w-64 before:h-64 before:-left-32 before:-top-32 before:bg-indigo-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-30 before:z-30 before:blur-[64px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden ${className}`}
        >
            <div className="relative h-full bg-[rgb(40,20,70)] rounded-[inherit] z-20 overflow-hidden">{children}</div>
        </div>
    );
}
