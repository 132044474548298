import Logo from "./logo";

export default function Footer() {
    return (
        <footer className="bg-gray-900 text-gray-300">
            <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between flex-col md:flex-row">
                <div className="flex-1 flex flex-col justify-center mb-4 md:mb-0 gap-2">
                    <div className="md:text-right text-center w-full">شركة المفهرس الذكي للذكاء الاصطناعي ذ.م.م.</div>
                    <div className="md:text-right text-center w-full">سجل تجاري: 2050186564</div>
                    <div className="md:text-right text-center w-full">المملكة العربية السعودية - الدمام</div>
                </div>

                {/* <div className="flex-1 flex flex-col justify-center items-center md:items-start ltr mb-4 md:mb-0">
                    <a
                        className="flex items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out"
                        href="https://x.com/InteliDexer"
                        aria-label="Twitter"
                    >
                        InteliDexer@
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z" />
                        </svg>
                    </a>
                </div> */}
                <div className="flex-1 flex flex-col justify-center items-center mb-4 md:mb-0 gap-2">
                    <a href="/static/files/terms-and-conditions.pdf" className="footer-link" target="_blank">
                        الشروط والأحكام
                    </a>
                    <a href="/static/files/privacy-policy.pdf" className="footer-link" target="_blank">
                        سياسة الخصوصية
                    </a>
                    <div className="text-center w-full">
                        © InteliDexer.com <span className="text-gray-500"> - </span>
                        جميع الحقوق محفوظة
                    </div>
                </div>
                <div className="flex-1 flex flex-col justify-center items-center md:items-end">
                    <Logo height={120} maxHeight={140} long={true} />
                </div>

                {/* <div className="flex-1 flex flex-col justify-center"></div>

                <div className="flex-1 flex flex-col justify-center"></div> */}
            </div>
        </footer>
    );
}
