import React, { useContext } from "react";
import { SelectionContext } from "./SelectionContextProvider";
import { Transition } from "@headlessui/react";

export default function GlowingGridContainer({ children }) {
    const { selection } = useContext(SelectionContext);
    return (
        <div className="flex items-center justify-center">
            <div className="relative w-48 h-48 flex justify-center items-center">
                <HaloEffect />
                <Grid />
                {React.Children.map(children, (child, index) => {
                    if (!React.isValidElement(child)) return null;

                    return (
                        <Transition
                            show={selection === index}
                            className="absolute"
                            enter="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 order-first"
                            enterFrom="opacity-0 -rotate-[60deg]"
                            enterTo="opacity-100 rotate-0"
                            leave="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700"
                            leaveFrom="opacity-100 rotate-0"
                            leaveTo="opacity-0 rotate-[60deg]"
                        >
                            <div className="relative flex items-center justify-center w-48 h-48 p-4 border border-transparent rounded-2xl shadow-2xl [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-2xl">
                                {React.cloneElement(child, {
                                    className: "relative fill-slate-200",
                                    width: "2500",
                                    height: "2500",
                                })}
                            </div>
                        </Transition>
                    );
                })}
            </div>
        </div>
    );
}

function HaloEffect() {
    return (
        <svg
            className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 will-change-transform pointer-events-none blur-md"
            width="480"
            height="480"
            viewBox="0 0 480 480"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient id="pulse-a" x1="50%" x2="50%" y1="100%" y2="0%">
                    <stop offset="0%" stopColor="#A855F7" />
                    <stop offset="76.382%" stopColor="#FAF5FF" />
                    <stop offset="100%" stopColor="#6366F1" />
                </linearGradient>
            </defs>
            <g fillRule="evenodd">
                <path
                    className="pulse"
                    fill="url(#pulse-a)"
                    fillRule="evenodd"
                    d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                />
                <path
                    className="pulse pulse-1"
                    fill="url(#pulse-a)"
                    fillRule="evenodd"
                    d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                />
                <path
                    className="pulse pulse-2"
                    fill="url(#pulse-a)"
                    fillRule="evenodd"
                    d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                />
            </g>
        </svg>
    );
}

function Grid() {
    return (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none w-[500px] h-[500px] rounded-full overflow-hidden [mask-image:_radial-gradient(black,_transparent_60%)]">
            <div className="h-[200%] animate-endless">
                <div className="absolute inset-0 [background:_repeating-linear-gradient(transparent,_transparent_48px,_theme(colors.white)_48px,_theme(colors.white)_49px)] blur-[2px] opacity-20" />
                <div className="absolute inset-0 [background:_repeating-linear-gradient(transparent,_transparent_48px,_theme(colors.purple.500)_48px,_theme(colors.purple.500)_49px)]" />
                <div className="absolute inset-0 [background:_repeating-linear-gradient(90deg,transparent,_transparent_48px,_theme(colors.white)_48px,_theme(colors.white)_49px)] blur-[2px] opacity-20" />
                <div className="absolute inset-0 [background:_repeating-linear-gradient(90deg,transparent,_transparent_48px,_theme(colors.purple.500)_48px,_theme(colors.purple.500)_49px)]" />
            </div>
        </div>
    );
}
