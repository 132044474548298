import React from "react";

const ScrollIndicator = ({ isVisible, delay = 0 }) => {
    return (
        <div
            className={`fixed left-1/2 bottom-14 -translate-x-1/2 flex flex-col items-center transition-all duration-500 z-50 
                ${!isVisible ? "opacity-0 translate-y-5 pointer-events-none" : "opacity-100"}`}
        >
            <div className="flex flex-col gap-0">
                {[...Array(3)].map((_, i) => (
                    <span
                        key={i}
                        className={`block w-5 h-5 border-r-[3px] border-b-[3px] border-white/90 rotate-45 -m-1 animate-bounce-arrow`}
                        style={{
                            animationDelay: `${i * 0.15}s`,
                            filter: "drop-shadow(2px 2px 0 rgba(0, 0, 0, 1)) drop-shadow(0 0 3px rgba(255, 255, 255, 0.5))",
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default ScrollIndicator;
