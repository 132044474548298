import DarkLogoImg from "../../images/logo-dark.png";
import LightLogoImg from "../../images/logo-light.png";
import LongLightLogoImg from "../../images/logo-long-light.png";
import LongDarkLogoImg from "../../images/logo-long-dark.png";
import LogoIcon from "../../images/logo-icon-light.png";

export default function Logo({
    className = "",
    width = 192,
    dark = false,
    dataAos,
    dataAosDelay,
    maxWidth = 80,
    height = 192,
    maxHeight = 192,
    long = false,
    icon = false,
}) {
    return (
        <img
            data-aos={dataAos}
            data-aos-delay={dataAosDelay}
            className={`max-w-none ${className}`}
            src={
                icon ? LogoIcon : dark ? (long ? LongDarkLogoImg : DarkLogoImg) : long ? LongLightLogoImg : LightLogoImg
            }
            style={{
                width: icon ? `${width}px` : long ? undefined : `${width}px`,
                height: icon ? `${width}px` : long ? `${height}px` : undefined,
                maxWidth: icon ? `${maxWidth}px` : long ? undefined : `${maxWidth}vw`,
                maxHeight: icon ? `${maxWidth}px` : long ? `${maxHeight}px` : undefined,
                display: "block",
            }}
            priority
            alt="Stellar"
        />
    );
}
