import BlueEclipse from "../../images/glow-bottom.svg";
import WhiteEclipse from "../../images/glow-top.svg";

export default function Eclipse({
    position = "bottom",
    eclipseColor = "blue",
    flipped = "false",
    width = 2000,
}) {
    return (
        <div
            className="absolute inset-0 -z-10 -mx-28 rounded-b-[3rem] pointer-events-none overflow-hidden"
            aria-hidden="true"
        >
            <div className={`absolute left-1/2 -translate-x-1/2 ${position}-0 -z-10`}>
                <img
                    src={eclipseColor === "white" ? WhiteEclipse : BlueEclipse}
                    className={`max-w-none ${flipped === "true" ? "scale-y-[-1]" : ""}`}
                    width={width}
                    priority
                    alt="Eclipse"
                />
            </div>
        </div>
    );
}

/*

            <div className="absolute left-1/2 -translate-x-1/2 top-0 -z-10">
                <img
                    src={Illustration}
                    className="max-w-none"
                    width={1404}
                    height={658}
                    alt="Features Illustration"
                />
            </div>

*/
